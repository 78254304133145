import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useAsyncFn } from "react-use";

// Context
import { PageSetup } from "../context/page-context";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { AddToCart } from "../product/add-to-cart";
import { VariantSelectors } from "../product/variant-selectors";
import { PreviewLightbox } from "../images/lightbox-gallery";
import { ProductArtistDesigner } from "../product/product-artist-designer";
import { StudioVoltaireArchive } from "../product/studio-voltaire-archive";
import { QuantityDropdown } from "../product/quantity-dropdown";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { formatPrice } from "../utils/format-price";

const Page = styled.div`
  margin: 75px 0 0 0;
  color: #0b646f;

  @media (max-width: 960px) {
    margin: 30px 0 40px 0;
  }

  & .product-container,
  & .remaining-product-images-container {
    padding: 0 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }

  & .product-container {
    margin: 0 0 70px 0;

    @media (max-width: 768px) {
      margin: 0 0 15px 0;
    }
  }

  & .breadcrumbs {
    & p {
      font-size: 12px;
      line-height: 28px;

      margin: 0 0 1em 0;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .single-image-container {
    cursor: pointer;

    & .gatsby-image-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    & img {
      object-fit: contain !important;
    }
  }

  & .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 70px;

    @media (max-width: 960px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .left-column {
      grid-column: 1 / 7;

      & .single-media-container {
        margin: 0;

        cursor: pointer;
      }

      & .aspect-ratio-image-container {
        & .content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          & .single-media-container {
            width: 100%;
            height: auto;
          }
        }
      }

      @media (max-width: 960px) {
        grid-column: unset;
      }
    }

    & .right-column {
      grid-column: 7 / 13;

      @media (max-width: 960px) {
        grid-column: unset;
        padding: 15px 10px;
        /* padding: 15px 10px 0 10px; */
      }

      &.grid {
        display: grid;
        grid-auto-rows: 20px auto 20px;
        grid-row-gap: 20px;

        @media (max-width: 960px) {
          display: block;
          grid-auto-rows: unset;
          grid-row-gap: unset;
        }

        & .text-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          margin: 0 auto;

          @media (max-width: 960px) {
            display: block;
            flex-direction: unset;
            align-items: unset;
            justify-content: unset;

            margin: 0;
          }
        }
      }

      & .text-container {
        max-width: 420px;

        @media (max-width: 960px) {
          max-width: unset;
        }

        & .product-title {
          margin: 0 0 50px 0;

          & h1,
          & h2,
          & p {
            /* font-size: 22px; */
            /* line-height: 28px; */
            font-size: 18px;
            line-height: 22px;

            margin: 0;

            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
              text-align: center;
            }
          }
        }

        & .product-description {
          margin: 0 0 15px 0;

          & p {
            /* font-size: 15px !important; */
            /* line-height: 20px !important; */

            font-size: 13px !important;
            line-height: 17px !important;

            letter-spacing: 0.02em !important;
            margin: 1em 0 !important;

            @media (max-width: 768px) {
              font-size: 12px !important;
              line-height: 17px !important;
            }
          }
        }

        & .product-details-container {
          margin: 0 0 20px 0;

          & .line {
            display: grid;
            grid-template-columns: 100px auto;
            grid-column-gap: 25px;

            & p {
              margin: 0;

              &.title {
                font-size: 11px;
                line-height: 17px;

                /* font-size: 12px;
                line-height: 20px; */

                @media (max-width: 768px) {
                  font-size: 11px;
                  line-height: 17px;
                }
              }

              &.text {
                font-size: 13px;
                line-height: 17px;

                /* font-size: 15px;
                line-height: 20px; */
                letter-spacing: 0.02em;

                @media (max-width: 768px) {
                  font-size: 12px;
                  line-height: 17px;
                }
              }
            }
          }
        }

        & .additional-product-text-container {
          margin: 20px 0 45px 0;
        }

        & .product-options-container {
          margin: 45px 0 50px 0;

          @media (max-width: 768px) {
            margin: 20px 0;
          }
        }

        & .product-buttons {
          max-width: 400px;

          & button {
            /* font-size: 15px; */
            font-size: 12px;
            line-height: 39px;
            letter-spacing: 0.02em;
            text-align: center;

            background-color: #f5eadf;
            color: #0b646f;

            width: 100%;
            height: 40px;

            @media (hover: hover) and (pointer: fine) {
              transition: 250ms color ease, 250ms background-color ease;

              &:hover {
                color: #fff;
                background-color: #0b646f;
              }
            }

            &:disabled {
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #0b646f;
                  background-color: #f5eadf;
                }
              }
            }
          }

          @media (max-width: 768px) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;

            z-index: 10;

            max-width: 100%;

            & button {
              font-size: 14px;
            }
          }
        }
      }

      & .product-information-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & a,
        & button {
          display: block;

          /* font-size: 12px;
          line-height: 20px; */

          font-size: 11px;
          line-height: 17px;

          margin: 0 20px 0 0;

          color: #0b646f;

          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #e9ccb2;
            }
          }

          @media (max-width: 768px) {
            font-size: 11px;
            line-height: 17px;
          }
        }
      }
    }
  }

  & .remaining-product-images-container {
    margin: 0 0 70px 0;

    & .single-media-container {
      grid-column: span 6;
    }
  }
`;

export const ProductPreview = ({ data }) => {
  const [quantity, setQuantity] = useState(1);
  const [available, setAvailable] = useState(false);

  // Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);

  const handleOptionChange = (index, value) => {};
  const hasVariants = data.variants.nodes.length > 1;

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 961px)");

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `product`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const media = data.media.edges.map((media, index) => {
    if (media.node.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}`}
          className={`single-image-container single-media-container`}
          onClick={() => {
            if (isDesktop) {
              setIsLightboxOpen(true);
              setActiveSlideNumber(index);
            }
          }}
        >
          <>
            {media.node.image.width > media.node.image.height ? (
              <>
                {index === 0 ? (
                  <AspectRatioImageContainer image={null} padding={125}>
                    <img src={media.node.image.originalSrc} draggable="false" />
                  </AspectRatioImageContainer>
                ) : (
                  <img src={media.node.image.originalSrc} draggable="false" />
                )}
              </>
            ) : (
              <AspectRatioImageContainer image={null} padding={125}>
                <img src={media.node.image.originalSrc} draggable="false" />
              </AspectRatioImageContainer>
            )}
          </>
        </div>
      );
    }
  });

  const firstMediaItem = media.filter((item, index) => index === 0);
  const remainingMediaItems = media.filter(
    (item, index) => index >= 1 && index <= 2
  );
  // const remainingMediaItems = media.filter((item, index) => index >= 1);

  const artistDesignerPageId = data.artist_designer;

  const finishingText = data.finishing;
  const editionSizeText = data.edition_size;
  const dimensionsText = data.dimensions;
  const additionalProductText = data.additional_product_text;
  const aboutTheArtworkText = data.about_the_artwork;

  const [productArtistDesigner, getArtistDesigner] = useAsyncFn(async id => {
    const response = await fetch(
      `/.netlify/functions/get-artist-designer-details`,
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    const result = await response.json();
    return result;
  }, []);

  return (
    <>
      <Page>
        <section className="product-container">
          <div className="breadcrumbs"></div>

          <div className="grid-12">
            <div className="left-column">
              <>{firstMediaItem}</>
            </div>

            <div className="right-column grid">
              <div className="spacer" />

              <div className="text-container">
                <div className="inner-text-container">
                  <div className="product-title">
                    <h2>
                      {data.artist_name !== null && data.artist_name.value}
                    </h2>
                    <h1>{data.title}</h1>

                    <p className="price">
                      {formatPrice(
                        "GBP",
                        parseInt(data.priceRange.minVariantPrice.amount) / 100
                      )}
                    </p>
                  </div>

                  <div
                    className="product-description news-gothic"
                    dangerouslySetInnerHTML={{
                      __html: data.descriptionHtml,
                    }}
                  />

                  <div className="product-details-container">
                    {editionSizeText !== null && (
                      <div className="line">
                        <p className="title">Edition Size</p>
                        <p className="text news-gothic">
                          {editionSizeText.value}
                        </p>
                      </div>
                    )}

                    {dimensionsText !== null && (
                      <div className="line">
                        <p className="title">Dimensions</p>
                        <p className="text news-gothic">
                          {dimensionsText.value}
                        </p>
                      </div>
                    )}

                    {finishingText !== null && (
                      <div className="line">
                        <p className="title">Finishing</p>
                        <p className="text news-gothic">
                          {finishingText.value}
                        </p>
                      </div>
                    )}
                  </div>

                  {additionalProductText !== null && (
                    <div className="additional-product-text-container">
                      <p className="news-gothic">
                        {additionalProductText.value}
                      </p>
                    </div>
                  )}

                  <div className="product-options-container">
                    <QuantityDropdown
                      inventoryQuantity={1}
                      setQuantity={setQuantity}
                    />

                    {hasVariants &&
                      data.options.map(({ name, values }, index) => (
                        <div key={`single_variant_${index}`}>
                          <VariantSelectors
                            handleOptionChange={handleOptionChange}
                            items={values}
                            name={name}
                            index={index}
                          />
                        </div>
                      ))}
                  </div>

                  <AddToCart
                    variantId={data.variants.nodes[0].id}
                    isPreview={true}
                    quantity={1}
                    available={data.variants.nodes[0].availableForSale}
                    product={data}
                    price={formatPrice(
                      "GBP",
                      data.priceRange.minVariantPrice.amount.price
                    )}
                  />
                </div>
              </div>

              <div className="product-information-container">
                <button className="uppercase">
                  → Enquire About This Artwork
                </button>
                <Link
                  className="uppercase"
                  to={`/delivery-returns-information/`}
                  state={{
                    from: `website`,
                  }}
                >
                  → Delivery And Returns Information
                </Link>
              </div>
            </div>
          </div>
        </section>

        {isDesktop && (
          <section className="remaining-product-images-container">
            <div className="grid-12">{remainingMediaItems}</div>
          </section>
        )}

        {artistDesignerPageId !== null && (
          <section className="artist-designer-container">
            <ProductArtistDesigner
              id={
                artistDesignerPageId !== null
                  ? artistDesignerPageId.value
                  : null
              }
              artistName={
                data.artist_name !== null ? data.artist_name.value : null
              }
              aboutTheArtworkText={
                aboutTheArtworkText !== null ? aboutTheArtworkText.value : null
              }
              productArtistDesigner={productArtistDesigner}
              getArtistDesigner={getArtistDesigner}
            />
          </section>
        )}

        {productArtistDesigner !== null && productArtistDesigner.length >= 1 && (
          <section className="studio-voltaire-archive">
            <StudioVoltaireArchive
              id={
                artistDesignerPageId !== null
                  ? artistDesignerPageId.value
                  : null
              }
              productArtistDesigner={productArtistDesigner}
              getArtistDesigner={getArtistDesigner}
            />
          </section>
        )}
      </Page>

      {isLightboxOpen && (
        <PreviewLightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          productMedia={data.media.edges}
          activeSlideNumber={activeSlideNumber}
        />
      )}
    </>
  );
};
