import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAsyncFn } from "react-use";

// Utils
import { getReturnedShopifyMetafield } from "../utils/get-metafield";

const StudioVoltaireArchiveContainer = styled.div`
  padding: 50px;

  background-color: #f5eadf;

  @media (max-width: 960px) {
    padding: 30px;
  }

  @media (max-width: 800px) {
    padding: 0 0 30px 0;
  }

  & .single-row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 70px;

    margin: 0 0 60px 0;

    & .left-column {
      grid-column: 1 / 7;

      & .image-caption {
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.02em;

        margin: 10px 0 0 0;
      }
    }

    & .right-column {
      grid-column: 8 / 13;
    }

    & .about-the-artwork {
      max-width: 470px;
      margin: 0 0 40px 0;

      & p {
        white-space: pre-wrap;
      }
    }

    & .placeholder-image {
      aspect-ratio: 3 / 2;
      border: 1px dashed black;
    }

    & .title-container {
      & h2 {
        /* font-size: 22px;
        line-height: 32px; */

        font-size: 18px;
        line-height: 26px;
      }
    }

    & .text-container {
      margin: 20px 0 0 0;

      & p {
        /* font-size: 15px;
        line-height: 20px; */
        /* font-size: 12px; */
        /* line-height: 16px; */

        letter-spacing: 0.02em;
        font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        & strong {
          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }

        & a {
          width: 120px;
          height: 30px;
          text-align: center;

          font-size: 10px;
          line-height: 32px;
          letter-spacing: 0.02em;

          color: #0b646f;

          display: block;
          background-color: #ffffff;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;

          @media (hover: hover) and (pointer: fine) {
            transition: 250ms color ease, 250ms background-color ease;

            &:hover {
              color: #fff;
              background-color: #0b646f;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    & .single-row {
      & .left-column {
        grid-column: 1 / 7;
      }

      & .right-column {
        grid-column: 7 / 13;
      }
    }
  }

  @media (max-width: 960px) {
    & .single-row {
      grid-column-gap: 30px;
    }
  }

  @media (max-width: 800px) {
    & .single-row {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;

      & .left-column {
        & .image-caption {
          padding: 0 20px;
        }
      }

      & .right-column {
        padding: 30px 20px 0 20px;
      }
    }
  }

  @media (max-width: 768px) {
    & .single-row {
      & .left-column {
        & .image-caption {
          font-size: 9px;
          line-height: 14px;
        }
      }

      & .right-column {
        & .title-container {
          & h2 {
            font-size: 14px;
            line-height: 17px;
          }
        }

        & .text-container {
          margin: 17px 0 0 0;

          & p {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.02em;
          }
        }
      }
    }
  }
`;

export const StudioVoltaireArchive = ({
  id,
  productArtistDesigner,
  getArtistDesigner,
}) => {
  const [archiveText, setArchiveText] = useState(null);
  const [archiveImageCaption, setArchiveImageCaption] = useState(null);

  useEffect(() => {
    if (id !== null) {
      getArtistDesigner(id);
    }
  }, [id]);

  const [archiveImage, getArchiveImage] = useAsyncFn(async id => {
    const response = await fetch(`/.netlify/functions/get-media-image`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();
    return result;
  }, []);

  useEffect(() => {
    if (
      productArtistDesigner.loading === false &&
      productArtistDesigner.value !== undefined
    ) {
      if (
        productArtistDesigner.value.metafields !== undefined &&
        productArtistDesigner.value.metafields.length >= 1
      ) {
        setArchiveText(
          getReturnedShopifyMetafield(
            productArtistDesigner.value.metafields,
            "studio_voltaire_archive_text"
          )
        );
        setArchiveImageCaption(
          getReturnedShopifyMetafield(
            productArtistDesigner.value.metafields,
            "sv_archive_image_caption"
          )
        );
        getArchiveImage(
          getReturnedShopifyMetafield(
            productArtistDesigner.value.metafields,
            "artist_image"
          )
        );
      }
    }
  }, [productArtistDesigner]);

  return (
    <StudioVoltaireArchiveContainer>
      <div className="single-row">
        <div className="left-column">
          {archiveImage.loading === false &&
            archiveImage.value !== undefined && (
              <img
                src={archiveImage.value.node.image.url}
                alt={archiveImage.value.node.image.altText}
                loading="lazy"
              />
            )}

          {archiveImageCaption !== null && (
            <p className="image-caption news-gothic">{archiveImageCaption}</p>
          )}
        </div>
        <div className="right-column">
          <div className="about-the-artwork">
            <div className="title-container">
              <h2 className="uppercase">Studio Voltaire Archive</h2>
            </div>

            {archiveText !== null && (
              <div
                className="text-container"
                dangerouslySetInnerHTML={{
                  __html: archiveText,
                }}
              />
            )}
          </div>
        </div>
      </div>{" "}
    </StudioVoltaireArchiveContainer>
  );
};
